import React, { useRef } from 'react';
import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { List } from '@mui/icons-material';
import TextField from '../../components/TextField';
import NumberField from '../../components/NumberField';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
  isError: boolean;
  isLoadingCustomer: boolean;
  isDebit: boolean;
  onList?: () => void;
  onBlurSale?: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlurCustomer: (
    focus: () => void,
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export default function Form({
  control,
  isSubmitting,
  isError,
  isLoadingCustomer,
  isDebit,
  onList,
  onBlurSale,
  onBlurCustomer,
}: Props) {
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const adornmentValue: JSX.Element = (
    <InputAdornment position="start">
      <Typography component="span" variant="caption">
        R$
      </Typography>
    </InputAdornment>
  );

  return (
    <>
      {isError && (
        <Card sx={{ border: '1px solid #AF3335 !important', height: 40 }}>
          <CardHeader
            sx={{ p: 1 }}
            title={
              <Typography
                variant="body2"
                color="primary.main"
                fontWeight="bold"
                textAlign="center"
              >
                Cliente não localizado
              </Typography>
            }
          />
        </Card>
      )}
      <Grid container spacing={2}>
        <Grid item md={4} xs={6}>
          <TextField
            id="cpf_cnpj"
            name="cpf_cnpj"
            control={control}
            {...(!isDebit && {
              sx: {
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#006600',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#006600',
                },
              },
            })}
            defaultValue=""
            variant="standard"
            mask="000.000.000-00"
            fullWidth
            label="CPF"
            required
            onBlur={e => {
              onBlurCustomer(() => {
                if (textFieldRef.current) {
                  textFieldRef.current.focus();
                  textFieldRef.current.select();
                }
              }, e);
            }}
            InputLabelProps={{
              shrink: true,
              sx: { fontWeight: 'bold', color: 'black' },
            }}
            disabled={isSubmitting || isLoadingCustomer}
            isLoadingAction={isLoadingCustomer}
          />
        </Grid>

        <>
          <Grid item md={onList ? 6 : 8} xs={6}>
            <TextField
              id="customername"
              name="customer_name"
              control={control}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Cliente"
              InputLabelProps={{ shrink: true }}
              disabled
              focused={false}
            />
          </Grid>
          {onList && (
            <Grid
              item
              md={2}
              xs={2}
              sx={{ display: 'flex', alignItems: 'end' }}
            >
              <IconButton aria-label="list" onClick={onList}>
                <List />
              </IconButton>
            </Grid>
          )}
        </>

        {!isDebit ? (
          <>
            <Grid item md={4} xs={4}>
              <NumberField
                id="sale_value"
                {...(!isDebit && { inputRef: textFieldRef })}
                name="sale_value"
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#006600',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#006600',
                  },
                }}
                control={control}
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Valor da Venda"
                decimalScale={2}
                onBlur={onBlurSale}
                InputLabelProps={{
                  shrink: true,
                  sx: { fontWeight: 'bold', color: 'black' },
                }}
                disabled={isSubmitting || isLoadingCustomer}
                startAdornment={adornmentValue}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <NumberField
                id="value"
                name="value"
                control={control}
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Previsão de Cashback"
                disabled
                focused={false}
                decimalScale={2}
                InputLabelProps={{ shrink: true }}
                startAdornment={adornmentValue}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <NumberField
                id="final"
                control={control}
                name="final"
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Novo Saldo"
                disabled
                focused={false}
                decimalScale={2}
                InputLabelProps={{ shrink: true }}
                startAdornment={adornmentValue}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={4} xs={4}>
              <NumberField
                id="balance"
                name="balance"
                control={control}
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Valor Disponível"
                disabled
                focused={false}
                decimalScale={2}
                InputLabelProps={{ shrink: true }}
                startAdornment={adornmentValue}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <NumberField
                id="value"
                {...(isDebit && { inputRef: textFieldRef })}
                name="value"
                control={control}
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Valor do Resgate"
                decimalScale={2}
                onBlur={onBlurSale}
                InputLabelProps={{
                  shrink: true,
                  sx: { fontWeight: 'bold', color: 'black' },
                }}
                disabled={isSubmitting || isLoadingCustomer}
                startAdornment={adornmentValue}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <NumberField
                id="final"
                name="final"
                control={control}
                variant="standard"
                defaultValue={0}
                fullWidth
                label="Saldo"
                disabled
                focused={false}
                decimalScale={2}
                InputLabelProps={{ shrink: true }}
                startAdornment={adornmentValue}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} xs={12} sx={{ mb: 2 }}>
          <TextField
            id="observation"
            name="observation"
            {...(!isDebit && {
              sx: {
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#006600',
                },

                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#006600',
                  },
                },
              },
            })}
            control={control}
            defaultValue=""
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            label="Observação"
            InputLabelProps={{
              shrink: true,
              sx: {
                fontWeight: 'bold',
                color: 'black',
              },
            }}
            disabled={isSubmitting || isLoadingCustomer}
          />
        </Grid>
      </Grid>
    </>
  );
}

Form.defaultProps = {
  isSubmitting: false,
  onList: undefined,
  onBlurSale: undefined,
};
