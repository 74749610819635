import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { validateCPF } from 'validations-br';
import { RemoveCircleOutline, Person } from '@mui/icons-material';
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ActionsDataTable from '../../components/ActionsDataTable';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import { ICashback, ICashbackInfo, ICustomer, IUserInfo } from '../../models';
import Form from './Form';
import Title from '../../components/Title';
import { useCan } from '../../hooks/useCan';
import {
  getBalance,
  getCashbacks,
  useCashbacks,
} from '../../services/hooks/useCashback';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import precisionRound, {
  formatCurrency,
  formatDateInfo,
  maskCPFCNPJ,
  maskPhone,
} from '../../utils';
import DialogX from '../../components/DialogX';
import snack from '../../components/SnackbarUtilsConfigurator';
import useLogin from '../../hooks/useLogin';

const schema = yup
  .object()
  .shape({
    cpf_cnpj: yup
      .string()
      .test('is-cpf', 'CPF inválido', value =>
        value ? validateCPF(value || '') : true
      )
      .required()
      .nullable(),
    type: yup.string().required().nullable(),
    customer_name: yup.string().nullable(),
    sale_value: yup.number().nullable(),
    value: yup.number().required().nullable(),
    final: yup.number().nullable(),
    balance: yup.number().nullable(),
  })
  .required();

yup.setLocale(ptForm);

export default function Cashback() {
  const dispatch = useDispatch();
  const { openDialog, closeDialog, utilitySelector } = useUtility();
  const { userSelector } = useLogin();
  const { data, isLoading, isFetching } = useCashbacks();
  const [cash, setCash] = useState<ICashback | null>();
  const [cashbacks, setCashbacks] = useState<ICashbackInfo[]>();
  const [customers, setCustomers] = useState<ICustomer[]>();
  const [customerSel, setCustomerSel] = useState<ICustomer>();
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [isNotCustomer, setIsNotCustomer] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isDialogCustomer, setIsDialogCustomer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDel, setIsDel] = useState(false);
  const [isDebit, setIsDebit] = useState(false);
  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<ICashback>({
      resolver: yupResolver(schema),
    });

  const isCreate = useCan({ view: 'cashback.create' });
  const isDelete = useCan({ view: 'cashback.delete' });

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      reset({ type: isDebit ? 'debit' : 'credit' });
      setIsNotCustomer(false);
    } else {
      setIsDebit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilitySelector.dialogOpen, reset]);

  useEffect(() => {
    setCashbacks(data);
  }, [data]);

  useEffect(() => {
    (async () => {
      if (customerSel && customerSel.id) {
        await getBalance(customerSel.id).then(balance => {
          setValue('balance', balance.balance);
          setValue('final', balance.balance);
        });
      } else {
        setValue('balance', 0);
        setValue('final', 0);
        setValue('value', 0);
        setValue('sale_value', 0);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSel]);

  const mapColumns = (cashback: ICashback) => {
    const result = {
      customer_id: cashback.customer?.id ?? '',
      type: cashback.type,
      value: cashback.value,
      observation: cashback.observation !== '' ? cashback.observation : null,
    };

    return result;
  };

  const createCashback = useMutation(
    async (cashback: ICashback) => {
      const obj = mapColumns(cashback);
      const response = await api.post<ICashback>(`cashbacks`, obj);

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('cashbacks');
        dispatch(closeDialog());
      },
    }
  );

  const deleteCashback = useMutation(
    async (id: string) => {
      await api.delete(`cashbacks/${id}`);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['cashbacks']);
      },
    }
  );

  const onOk = async () => {
    if (cash) {
      setIsSubmitting(true);

      if (isDel) {
        if (cash.id) {
          await deleteCashback.mutateAsync(cash.id).finally(() => {
            setIsSubmitting(false);
            setIsDialog(false);
          });
        } else {
          snack.warning('Objeto inválido!');
        }
      } else {
        await createCashback.mutateAsync(cash).finally(() => {
          setIsSubmitting(false);
          setIsDialog(false);
        });
      }
    } else {
      snack.warning('Objeto inválido!');
    }
  };

  const handleCloseCustomer = () => {
    setCustomerSel(undefined);
    setIsDialogCustomer(false);
  };

  const onCustomerOk = () => {
    if (!customerSel) {
      snack.warning('Por favor selecione um cliente!');
      return;
    }

    setValue('customer', customerSel);
    setValue('customer_name', customerSel.name);
    handleCloseCustomer();
  };

  const getCustomers = async (focus: () => void, cpfCnpj?: string) => {
    if (!isDialog) {
      setIsLoadingCustomer(true);
      try {
        if (cpfCnpj && cpfCnpj !== '' && cpfCnpj.length === 14) {
          if (validateCPF(cpfCnpj)) {
            const { data: listCustomers } = await api.get<ICustomer[]>(
              `customers?cpf_cnpj=${cpfCnpj.replace(/\D+/g, '')}`
            );

            setCustomers(listCustomers);
            if (listCustomers && listCustomers.length > 0) {
              if (listCustomers.length === 1) {
                setValue('customer', listCustomers.at(0));
                setValue('customer_name', listCustomers.at(0)?.name);
                setCustomerSel(listCustomers.at(0));
                setIsNotCustomer(false);
              } else {
                setIsNotCustomer(false);
                setIsDialogCustomer(true);
              }
            } else {
              snack.warning('Cliente não localizado!');
              setValue('customer', undefined);
              setValue('customer_name', undefined);
              setCash(null);
              setIsNotCustomer(true);
              setCustomerSel(undefined);
            }
          } else {
            snack.warning('CPF inválido!');
            setValue('customer', undefined);
            setValue('customer_name', undefined);
            setCash(null);
            setIsNotCustomer(true);
            setCustomerSel(undefined);
          }
        } else {
          setValue('customer', undefined);
          setValue('customer_name', null);
          setCash(null);
          setCustomerSel(undefined);
          setIsNotCustomer(false);
        }
      } finally {
        setIsLoadingCustomer(false);

        focus();
      }
    }
  };

  const onBlurSale = (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event?.preventDefault();

    const newValue = event?.target.value;
    const balanceCust = getValues('balance');

    if (newValue) {
      const percentage = userSelector.affiliate.cashback_percentage ?? 0;
      const valueStr: string = newValue.replace(/\./g, '');
      const sale = parseFloat(valueStr.replace(',', '.'));

      if (sale > 0 && percentage > 0) {
        if (!isDebit) {
          const calcValue = precisionRound((sale * percentage) / 100);

          setValue('value', calcValue);
          setValue('final', calcValue + balanceCust);
        } else {
          if (sale > balanceCust) {
            setValue('value', 0);
            setValue('final', balanceCust);
            snack.warning('Valor do resgate é inválido!');
            return;
          }

          setValue('final', balanceCust - sale);
        }
      } else {
        setValue('value', 0);
        setValue('balance', balanceCust);
        setValue('final', balanceCust);
      }
    } else {
      setValue('value', 0);
      setValue('balance', balanceCust);
      setValue('final', balanceCust);
    }
  };

  const onBlurCustomer = (
    focus: () => void,
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event?.preventDefault();
    getCustomers(focus, event?.target?.value);
  };

  const onSubmit = async (formData: ICashback) => {
    if (!formData.customer) {
      snack.warning('Cliente não informado!');
      return;
    }

    if (formData.value <= 0) {
      snack.warning('Valor inválido!');
      return;
    }

    if (formData.type === 'debit' && formData.value > (formData.balance ?? 0)) {
      snack.warning('Valor informado superior ao saldo atual!');
      return;
    }

    setCash(formData);
    setIsDel(false);
    setIsDialog(true);
  };

  const onDelete = (obj: ICashback) => {
    if (obj.order_id && obj.order_id !== '') {
      snack.warning('Não é permitido excluir lançamentos automáticos!');
      return;
    }

    setCash({ ...obj, type: obj.value > 0 ? 'credit' : 'debit' });
    setIsDel(true);
    setIsDialog(true);
  };

  const actions = ActionsDataTable(
    false,
    cashbacks || [],
    isDelete ? onDelete : undefined,
    undefined,
    true
  );

  const numberTemplate = (value: number) => {
    return (
      <Label variant="ghost" color={(value > 0 && 'success') || 'error'}>
        {value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Label>
    );
  };

  const userTemplate = (value?: IUserInfo) => {
    return <div>{value?.name}</div>;
  };

  const originCashback = (value: string) => {
    return (
      <Label
        variant="ghost"
        color={
          (value === 'local' && 'warning') ||
          (value === 'delivery' && 'info') ||
          'success'
        }
      >
        {(value === 'local' && 'Local') ||
          (value === 'delivery' && 'Entrega') ||
          'Retirada'}
      </Label>
    );
  };

  const situationCashback = (value: string) => {
    return (
      <Label
        variant="ghost"
        color={
          (value === 'pending' && 'info') ||
          (value === 'canceled' && 'error') ||
          'success'
        }
      >
        {(value === 'pending' && 'Pendente') ||
          (value === 'canceled' && 'Cancelado') ||
          'Confirmado'}
      </Label>
    );
  };

  const dateFormat = (valueX: string) => {
    return valueX && <div>{formatDateInfo(valueX, 'DD/MM/YYYY HH:mm')}</div>;
  };

  const columns = [
    {
      name: 'created_at',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string) => dateFormat(value),
      },
    },
    {
      name: 'customer_name',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'customer_cpf_cnpj',
      label: 'CPF',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => maskCPFCNPJ(value),
      },
    },
    {
      name: 'order_number',
      label: 'Pedido',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'origin',
      label: 'Origem',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => originCashback(value),
      },
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value),
      },
    },
    {
      name: 'user',
      label: 'Usuário',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value?: IUserInfo) => userTemplate(value),
      },
    },
    {
      name: 'situation',
      label: 'Situação',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => situationCashback(value),
      },
    },
  ];

  if (actions && isDelete) {
    columns.push(actions);
  }

  const refresh = async () => {
    setIsLoadingX(true);
    return getCashbacks()
      .then(result => setCashbacks(result))
      .finally(() => {
        setIsLoadingX(false);
      });
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleCloseCashbackGenerate = () => {
    setCash(null);
    setIsDialog(false);
  };

  const handleListItemClick = (customer: ICustomer) => {
    setCustomerSel(customer);
  };

  const onList = () => {
    if (customers) {
      setIsDialogCustomer(true);
    }
  };

  const actionRemove = () => {
    setIsDebit(true);
    setIsNotCustomer(false);
    dispatch(openDialog(''));
  };

  const onCashNeg: React.ReactNode = (
    <Tooltip title="Resgatar">
      <IconButton onClick={() => actionRemove()}>
        <RemoveCircleOutline />
      </IconButton>
    </Tooltip>
  );

  return (
    <Page>
      <DataTable
        title={
          <Title
            description="Cashbacks"
            isLoading={isLoading || isFetching || isLoadingX}
          />
        }
        titleAdd="Lançamento"
        data={cashbacks || []}
        columns={columns}
        isLoading={isLoading || isFetching}
        refresh={refresh}
        hideActionAddToolbar={!isCreate}
        action={() => onCashNeg}
      />
      <DialogForm
        titleDiag={isDebit ? 'Resgate de Cashback' : 'Lançamento de Cashback'}
        maxForm="sm"
        open={utilitySelector.dialogOpen}
        isLoading={isLoadingX}
        backgroundColor={isDebit ? '#ffe6e6' : '#e0ffe0'}
        bgColorButton={isDebit ? 'primary.main' : '#006600'}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form
          control={control}
          isSubmitting={isSubmitting}
          onBlurCustomer={onBlurCustomer}
          onList={(customers?.length ?? 0) <= 1 ? undefined : onList}
          isLoadingCustomer={isLoadingCustomer}
          onBlurSale={onBlurSale}
          isError={isNotCustomer}
          isDebit={isDebit}
        />
      </DialogForm>
      <DialogX
        titleDiag={`Confirma a ${isDel ? 'exclusão do' : 'movimentação de'} ${
          cash?.type === 'credit' ? 'cashback' : 'resgate'
        }?`}
        open={isDialog}
        onOk={() => onOk()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseCashbackGenerate}
        {...(!isDel && { backgroundColor: isDebit ? '#ffe6e6' : '#e0ffe0' })}
        {...(!isDel && { bgColorButton: isDebit ? 'primary.main' : '#006600' })}
        isLoading={isSubmitting}
        autoFocus
      >
        {isSubmitting ? (
          <Stack
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Stack spacing={1} pl={3} pr={3}>
            <Typography variant="body1" fontWeight="bold">{`${
              cash?.customer?.name
            } - ${maskCPFCNPJ(cash?.customer?.cpf_cnpj ?? '')}`}</Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="secondary.main"
            >
              {maskPhone(cash?.customer?.cellphone ?? '')}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              {!isDel && (
                <Stack direction="row">
                  <Typography variant="body1" fontWeight="bold">
                    Saldo:
                  </Typography>
                  <Typography variant="body1" fontWeight="bold" ml={2}>
                    {formatCurrency(cash?.balance ?? 0)}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    color:
                      cash?.type === 'credit'
                        ? 'success.darker'
                        : 'primary.dark',
                  }}
                >
                  {cash?.type === 'credit' ? 'Cashback' : 'Resgate'}
                </Typography>
                <Chip
                  variant="outlined"
                  size="small"
                  label={formatCurrency(Math.abs(cash?.value ?? 0))}
                  sx={{
                    width: 140,
                    fontSize: '1rem',
                    fontWeight: 900,
                    ml: 1.5,
                    border:
                      cash?.type === 'credit'
                        ? '1px solid #08660D'
                        : '1px solid #8a282a',
                    color:
                      cash?.type === 'credit'
                        ? 'success.darker'
                        : 'primary.dark',
                  }}
                />
              </Stack>
            </Stack>
            {cash?.observation && (
              <Stack direction="row">
                <Typography variant="body1" fontWeight="bold">
                  Observação:
                </Typography>
                <Typography variant="body2" mt={0.25} ml={2}>
                  {cash.observation}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </DialogX>
      <DialogX
        titleDiag="Cliente"
        open={isDialogCustomer}
        onOk={onCustomerOk}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseCustomer}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {customers?.map((customer: ICustomer, key: number) => {
            return (
              <ListItem
                button
                key={key}
                selected={customer.id === customerSel?.id}
                onClick={() => handleListItemClick(customer)}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={customer.name}
                  secondary={`${maskPhone(customer.cellphone)} - ${
                    customer.email
                  }`}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogX>
    </Page>
  );
}
