/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CircularProgress,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Breakpoint,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Form from '../Form';
import { Progress } from './styles';

interface Props {
  titleDiag: string;
  subTitle?: string;
  isLoading?: boolean;
  handleClose: () => void;
  onSubmit: () => Promise<void>;
  isSubmitting?: boolean;
  maxForm?: Breakpoint;
  cancelText?: string;
  submitText?: string;
  hideSubmit?: boolean;
  backgroundColor?: string;
  bgColorButton?: string;
}

function DialogForm({
  titleDiag,
  subTitle,
  isLoading,
  handleClose,
  onSubmit,
  isSubmitting,
  maxForm,
  cancelText,
  submitText,
  hideSubmit,
  backgroundColor,
  bgColorButton,
  children,
  ...dialogProps
}: DialogProps & Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      fullWidth
      maxWidth={maxForm}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          backgroundColor,
        },
      }}
    >
      <DialogTitle id="form-dialog-title">{titleDiag || ''}</DialogTitle>

      <DialogContent>
        {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
        {isLoading ? (
          <Progress>
            <CircularProgress color="primary" />
          </Progress>
        ) : (
          <Form
            onClose={handleClose}
            onSubmit={onSubmit}
            cancelText={cancelText}
            submitText={submitText || 'Salvar'}
            hideSubmit={hideSubmit}
            bgColorButton={bgColorButton}
            isSubmitting={isSubmitting}
          >
            {children}
          </Form>
        )}
      </DialogContent>
    </Dialog>
  );
}

DialogForm.defaultProps = {
  subTitle: '',
  isLoading: false,
  isSubmitting: false,
  maxForm: 'sm',
  cancelText: 'Cancelar',
  submitText: 'Salvar',
  hideSubmit: false,
  backgroundColor: undefined,
  bgColorButton: 'primary.main',
};

export default DialogForm;
