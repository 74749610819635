import { IBootWhats } from '../../models';
import api from '../api';

export async function getQrCode(affiliateId: string): Promise<IBootWhats> {
  const { data } = await api.get<IBootWhats>(
    `affiliate/whatsapp-bot/${affiliateId}/qr-code`
  );

  return data;
}

export async function disconnect(affiliateId: string) {
  const result = await api.get(
    `/affiliate/whatsapp-bot/${affiliateId}/disconnect`
  );

  return result.status === 204;
}
