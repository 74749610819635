import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AppMenu from '../AppMenu';
import useLogin from '../../hooks/useLogin';
import { Container, Title } from './styles';
import InfoCompany from '../InfoCompany';
import { useView } from '../../hooks/useView';
import BootWhats from '../BootWhats';
import { StatusBootWhats } from '../../models';

interface Props {
  title?: string;
  hideInfoCompany?: boolean;
  children: React.ReactNode;
}

export default function Page({ title, hideInfoCompany, children }: Props) {
  const [isOpenConfigBoot, setIsOpenConfigBoot] = useState(false);

  const { userSelector, onGetUserInfo } = useLogin();
  const views = useView();
  const location = useLocation();

  const onGetUser = useCallback(async () => {
    await onGetUserInfo();
  }, [onGetUserInfo]);

  useEffect(() => {
    onGetUser();
  }, [onGetUser]);

  const closeConfigBoot = () => {
    setIsOpenConfigBoot(false);
  };

  useEffect(() => {
    if (
      location.pathname !== '/configuracoes' &&
      userSelector.affiliate.whatsapp_session_status &&
      userSelector.affiliate.whatsapp_session_status !==
        StatusBootWhats.NotFound &&
      userSelector.affiliate.whatsapp_session_status !==
        StatusBootWhats.Connected
    ) {
      setIsOpenConfigBoot(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelector.affiliate.whatsapp_session_status]);

  return (
    <Container>
      <CssBaseline />
      <AppMenu views={views}>
        {userSelector.isFetching ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              minHeight: '80vh',
            }}
          >
            <img src="app-logo.webp" alt="Mercurius" width="200" />
            <CircularProgress color="primary" sx={{ marginTop: 5 }} />
          </Box>
        ) : (
          <>
            {!hideInfoCompany && <InfoCompany />}
            {title && <Title>{title}</Title>}
            {children}
          </>
        )}
      </AppMenu>
      <BootWhats
        isOpen={isOpenConfigBoot}
        affiliateId={userSelector.affiliate.id ?? ''}
        handleClose={() => closeConfigBoot()}
      />
    </Container>
  );
}

Page.defaultProps = {
  title: '',
  hideInfoCompany: false,
};
