import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Cached';
import { withStyles } from '@mui/styles';
import useUtility from '../../hooks/useUtility';

interface Props {
  titleAdd: string;
  hideActionAddToolbar?: boolean;
  refresh?: () => Promise<void>;
  action?: () => React.ReactNode;
}

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomToolbar({
  hideActionAddToolbar,
  titleAdd,
  refresh,
  action,
}: Props) {
  const dispatch = useDispatch();
  const { openDialog } = useUtility();

  const actionAdd = () => {
    dispatch(openDialog(''));
  };

  return (
    <>
      {refresh && (
        <Tooltip title="Atualizar">
          <IconButton onClick={refresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}

      {!hideActionAddToolbar && (
        <Tooltip title={titleAdd}>
          <IconButton onClick={actionAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {action && action()}
    </>
  );
}

CustomToolbar.defaultProps = {
  hideActionAddToolbar: false,
  refresh: undefined,
  action: undefined,
};

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(
  CustomToolbar
);
