import React from 'react';
import Button from '../Button';

interface Props {
  onSubmit: () => Promise<void>;
  onClose?: () => void;
  isSubmitting?: boolean;
  cancelText?: string;
  submitText?: string;
  hideSubmit?: boolean;
  bgColorButton?: string;
  children: React.ReactNode;
}

function Form({
  onSubmit,
  onClose,
  isSubmitting,
  cancelText,
  submitText,
  hideSubmit,
  bgColorButton,
  children,
}: Props) {
  return (
    <form onSubmit={onSubmit}>
      {children}

      <div style={{ float: 'right' }}>
        {!hideSubmit && (
          <Button
            sx={{
              width: 100,
              background: bgColorButton,
              '&:hover': {
                backgroundColor: bgColorButton,
                color: '#fff',
              },
            }}
            id="submit"
            className="submitBtn"
            caption={submitText || 'Salvar'}
            type="submit"
            loading={isSubmitting}
          />
        )}

        {onClose && (
          <Button
            sx={{
              width: 100,
              marginLeft: 1,
              background: '#ffffff',
              color: bgColorButton,
              borderColor: bgColorButton,
              border: 0.5,
              '&:hover': {
                backgroundColor: bgColorButton,
                color: '#fff',
              },
            }}
            id="cancel"
            className="submitBtn"
            caption={cancelText || 'Cancelar'}
            onClick={onClose}
            {...(isSubmitting && { disabled: true })}
          />
        )}
      </div>
    </form>
  );
}

Form.defaultProps = {
  onClose: undefined,
  isSubmitting: false,
  cancelText: 'Cancelar',
  submitText: 'Salvar',
  hideSubmit: false,
  bgColorButton: 'primary.main',
};

export default Form;
