/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { StatusBootWhats } from '../models';

export function getFirstName(name = ''): string[] {
  return name.split(' ').slice(0, 1);
}

export function getEnumKey(key: string, enumRef: any): string {
  return String(
    (Object.keys(enumRef) as Array<keyof typeof enumRef>).filter(
      current => enumRef[current] === key
    )[0]
  );
}

export function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

export function maskCPFCNPJ(v: string) {
  if (!v) {
    return '';
  }

  let result = '';
  result = v.replace(/\D/g, '');

  if (result.length <= 11) {
    result = result.replace(/(\d{3})(\d)/, '$1.$2');
    result = result.replace(/(\d{3})(\d)/, '$1.$2');
    result = result.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    result = result.replace(/^(\d{2})(\d)/, '$1.$2');
    result = result.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    result = result.replace(/\.(\d{3})(\d)/, '.$1/$2');
    result = result.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return result;
}

export function maskPhone(phone: string) {
  if (!phone) {
    return '';
  }

  if (phone.length > 10) {
    return phone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
  }

  return phone.replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3');
}

export function maskCEP(cep: string) {
  if (!cep) {
    return '';
  }

  return cep.replace(/^(\d{5})(\d{3}).*/, '$1-$2');
}

export function formatCurrency(value: number): string {
  if (value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  return 'R$ 0,00';
}

export function formatDate(value?: string): string {
  if (value) {
    return moment(value).format('YYYY-MM-DD');
  }

  return '';
}

export function formatDatetime(value?: Date): string {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }

  return '';
}

export function formatHour(value?: Date): string {
  if (value) {
    return moment(value).format('HH:mm');
  }

  return '';
}

export function formatDateInfo(value?: string, format?: string): string {
  if (value) {
    return moment(value).format(format);
  }

  return '';
}

export function isBirthday(value?: string): boolean {
  if (value) {
    const dayCustomer = moment(value, 'YYYY-MM-DD').format('DD');
    const monthCustomer = moment(value, 'YYYY-MM-DD').format('MM');

    const dayToday = moment().format('DD');
    const monthToday = moment().format('MM');

    if (dayCustomer === dayToday && monthCustomer === monthToday) {
      return true;
    }
  }

  return false;
}

export function formatDeliveryTime(value: number): string {
  const duration = moment.duration(value, 'minutes');

  if (value < 60) {
    return `${duration.asMinutes().toString().padStart(2, '0')}min`;
  }

  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let result = '';

  if (days > 0) {
    result = `${days}d`;
  }

  if (hours > 0) {
    if (days > 0) {
      result += ' ';
    }

    result += `${hours}h`;
  }

  if (minutes > 0) {
    if (days > 0 && hours <= 0) {
      result += ' ';
    }

    result += `${hours > 0 ? minutes.toString().padStart(2, '0') : minutes}`;

    if (hours > 0) {
      result += 'min';
    }
  }

  return result;
}

export function isNotificationSupported(): boolean {
  return 'Notification' in window;
}

export function getStatusBootWhats(status: StatusBootWhats): string {
  if (status === StatusBootWhats.NotFound) {
    return 'Não configurado';
  }

  if (status === StatusBootWhats.NotConnected) {
    return 'Não conectado';
  }

  if (status === StatusBootWhats.Closed) {
    return 'Não conectado';
  }

  if (status === StatusBootWhats.Connected) {
    return 'Conectado';
  }

  return 'Inválido';
}

export default function precisionRound(number?: number, precision = 2): number {
  if (!number) {
    return 0;
  }

  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}
