import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { Warning } from '@mui/icons-material';
import DialogX from '../DialogX';
import snack from '../SnackbarUtilsConfigurator';
import { disconnect, getQrCode } from '../../services/hooks/useBootWhats';
import { IBootWhats, StatusBootWhats } from '../../models';
import useInterval from '../../hooks/useInterval';
import { setStatusWhatsBoot } from '../../store/userLogin';

interface Props {
  isOpen: boolean;
  affiliateId: string;
  handleClose: () => void;
}

export default function BootWhats({ isOpen, affiliateId, handleClose }: Props) {
  const [response, setResponse] = useState<IBootWhats>();
  const [isLoading, setIsLoading] = useState(false);
  const [isQRCodeEmpty, setIsQRCodeEmpty] = useState(true);
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const onClose = () => {
    setResponse(undefined);
    setIsQRCodeEmpty(true);
    handleClose();
  };

  useEffect(() => {
    if (affiliateId && affiliateId !== '' && isOpen) {
      setIsLoading(true);
      getQrCode(affiliateId)
        .then(data => setResponse(data))
        .finally(() => setIsLoading(false));
    }
  }, [affiliateId, isOpen]);

  useEffect(() => {
    if (response) {
      dispatch(setStatusWhatsBoot(response.status));

      if (response.status === StatusBootWhats.Connected) {
        snack.success('Bot do WhatsApp foi conectado com sucesso');
        onClose();
      }

      if (response.qr_code !== null) {
        setIsQRCodeEmpty(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useInterval(() => {
    if (
      affiliateId &&
      affiliateId !== '' &&
      !isLoading &&
      isOpen &&
      !isDisconnect
    ) {
      // setIsLoading(true);
      getQrCode(affiliateId).then(data => setResponse(data));
    }
  }, 10000);

  const onDisconnect = () => {
    setIsDisconnect(true);
    disconnect(affiliateId)
      .then((result: boolean) => {
        if (result) {
          dispatch(setStatusWhatsBoot(StatusBootWhats.NotFound));
          snack.success('Bot do WhatsApp foi desconectado com sucesso');
          onClose();
        }
      })
      .finally(() => setIsDisconnect(false));
  };

  const handleCloseConfirm = () => {
    setIsConfirm(false);
  };

  const ExecuteConfirm = () => {
    onDisconnect();

    setIsConfirm(false);
  };

  return (
    <>
      <DialogX
        titleDiag="WhatsApp Bot"
        open={isOpen}
        captionOk="Desconectar"
        justifySpaceBetweenActions
        onOk={() => setIsConfirm(true)}
        captionCancel="Fechar"
        handleClose={onClose}
        isLoading={isDisconnect}
        autoFocus={false}
      >
        <Box
          sx={{
            display: 'flex',
            minHeight: 300,
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {location.pathname !== '/configuracoes' && (
            <Card
              sx={{
                border: '1px solid #FFC107 !important',
                minHeight: 60,
                mb: 1,
                mt: 1,
              }}
            >
              <CardHeader
                sx={{ p: 1 }}
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Warning sx={{ marginRight: 1, color: 'warning.main' }} />
                    <Typography
                      sx={{ flexGrow: 1, textAlign: 'center' }}
                      variant="body2"
                      color="secondary.main"
                      fontWeight="bold"
                      textAlign="center"
                      display="block"
                    >
                      O bot do WhatsApp foi desconectado.
                      <br /> Por favor, leia o QRCode novamente para reconectar
                    </Typography>
                  </Box>
                }
              />
            </Card>
          )}

          {isLoading || isQRCodeEmpty || isDisconnect ? (
            <>
              <CircularProgress
                color="primary"
                size={40}
                sx={{
                  position: 'absolute',
                  opacity: 1,
                  top: location.pathname !== '/configuracoes' ? '56%' : '47%',
                  left: '47%',
                }}
              />

              <img
                src="qr-code.webp"
                style={{ alignSelf: 'center', opacity: 0.3 }}
                alt="QrCode"
                width={320}
                height={320}
              />
            </>
          ) : (
            response &&
            response.qr_code && (
              <QRCode
                style={{ alignSelf: 'center' }}
                value={response?.qr_code}
                logoImage={isLoading ? undefined : '/favicon.webp'}
                size={300}
              />
            )
          )}
        </Box>
      </DialogX>
      <DialogX
        titleDiag="WhatsApp Bot"
        open={isConfirm}
        onOk={() => ExecuteConfirm()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseConfirm}
        autoFocus
      >
        <Typography variant="body2">Confirma a desconexão ?</Typography>
      </DialogX>
    </>
  );
}
