import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ActionBootWhats, IConfiguration, StatusBootWhats } from '../../models';
import { modelPrintOrder } from '../../statics';
import Page from '../../components/Page';
import Form from '../../components/Form';
import PaperInfo from '../../components/PaperInfo';
import Title from '../../components/Title';
import snack from '../../components/SnackbarUtilsConfigurator';
import useLogin from '../../hooks/useLogin';
import { getStatusBootWhats } from '../../utils';
import Button from '../../components/Button';
import BootWhats from '../../components/BootWhats';
import Label from '../../components/Label';
import { disconnect } from '../../services/hooks/useBootWhats';
import { setStatusWhatsBoot } from '../../store/userLogin';
import DialogX from '../../components/DialogX';
import Affiliate from '../Affiliate';

const schema = yup
  .object()
  .shape({
    order_print_model: yup.string().required().nullable(),
  })
  .required();

yup.setLocale(ptForm);

export default function Configure() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IConfiguration>({
    resolver: yupResolver(schema),
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [actionConfig, setActionConfig] = useState<ActionBootWhats>();
  const [descriptionConfirm, setDescriptionConfirm] = useState('');
  const { userSelector } = useLogin();
  const dispatch = useDispatch();

  useEffect(() => {
    const strConfigurations = localStorage.getItem('Configurations');

    if (strConfigurations) {
      const configurations = JSON.parse(strConfigurations) as IConfiguration;
      reset({ order_print_model: configurations.order_print_model });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (formData: IConfiguration) => {
    localStorage.setItem('Configurations', JSON.stringify(formData));
    snack.success('Configurações atualizadas com sucesso');
  };

  const openConfigBoot = () => {
    setIsOpen(true);
  };

  const closeConfigBoot = () => {
    setIsOpen(false);
  };

  const onDisconnect = () => {
    setIsDisconnect(true);
    disconnect(userSelector.affiliate.id ?? '')
      .then((result: boolean) => {
        if (result) {
          dispatch(setStatusWhatsBoot(StatusBootWhats.NotFound));
        }
      })
      .finally(() => setIsDisconnect(false));
  };

  const openConfirm = (action: ActionBootWhats) => {
    setActionConfig(action);

    if (action === ActionBootWhats.ReadQrCode) {
      setDescriptionConfirm('Confirma a leitura do qrCode ?');
    } else if (action === ActionBootWhats.Disconnect) {
      setDescriptionConfirm('Confirma a desconexão ?');
    }

    setIsConfirm(true);
  };

  const handleCloseConfirm = () => {
    setIsConfirm(false);
  };

  const ExecuteConfirm = () => {
    if (actionConfig === ActionBootWhats.ReadQrCode) {
      openConfigBoot();
    } else if (actionConfig === ActionBootWhats.Disconnect) {
      onDisconnect();
    }

    setIsConfirm(false);
  };

  return (
    <>
      <Page>
        <PaperInfo>
          <Box sx={{ width: '100%', pt: 1, pb: 2 }}>
            <Title description="Configurações" />
            <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
              <FormControl variant="standard" sx={{ mt: 2, mb: 4 }} fullWidth>
                <InputLabel htmlFor="type-required" shrink>
                  Modelo de Impressão de Pedido
                </InputLabel>
                <Controller
                  name="order_print_model"
                  control={control}
                  defaultValue="model1_80mm"
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ textAlign: 'left' }}
                      id="order_print_model"
                      variant="standard"
                      color="primary"
                      label="Impressora"
                      required
                      value={field.value || 'model1_80mm'}
                    >
                      {modelPrintOrder.map(model => (
                        <MenuItem key={model.id} value={model.id}>
                          <Typography>{model.description}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Form>
          </Box>
        </PaperInfo>
        <PaperInfo>
          <Box sx={{ width: '100%', pt: 1, pb: 2 }}>
            <Typography component="span" variant="h6" sx={{ color: 'black' }}>
              WhatsApp Bot <WhatsApp sx={{ color: '#25d366', pt: 0.5 }} />
            </Typography>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                Situação:{' '}
                <Label
                  variant="ghost"
                  color={
                    userSelector.affiliate.whatsapp_session_status ===
                    StatusBootWhats.Connected
                      ? 'success'
                      : 'error'
                  }
                >
                  {getStatusBootWhats(
                    userSelector.affiliate.whatsapp_session_status ??
                      StatusBootWhats.NotFound
                  )}
                </Label>
              </Typography>
              <Box>
                <Button
                  id="readQrCode"
                  sx={{
                    mr: 1,
                    background: '#ffffff',
                    color: 'primary.main',
                    borderColor: 'primary.main',
                    border: 0.5,
                    '&:hover': {
                      backgroundColor: 'primary.main',
                      color: '#fff',
                    },
                  }}
                  caption="Ler QRCode"
                  disabled={
                    userSelector.affiliate.whatsapp_session_status ===
                    StatusBootWhats.Connected
                  }
                  onClick={() => openConfirm(ActionBootWhats.ReadQrCode)}
                />
                <Button
                  id="disconnected"
                  sx={{
                    width: 100,
                  }}
                  loading={isDisconnect}
                  disabled={
                    userSelector.affiliate.whatsapp_session_status !==
                    StatusBootWhats.Connected
                  }
                  caption="Desconectar"
                  onClick={() => openConfirm(ActionBootWhats.Disconnect)}
                />
              </Box>
            </Box>
          </Box>
        </PaperInfo>
      </Page>
      <BootWhats
        isOpen={isOpen}
        affiliateId={userSelector.affiliate.id ?? ''}
        handleClose={() => closeConfigBoot()}
      />
      <DialogX
        titleDiag="WhatsApp Bot"
        open={isConfirm}
        onOk={() => ExecuteConfirm()}
        captionOk="Confirmar"
        captionCancel="Fechar"
        handleClose={handleCloseConfirm}
        isLoading={isDisconnect}
        autoFocus
      >
        <Typography variant="body2">{descriptionConfirm}</Typography>
      </DialogX>
    </>
  );
}
