import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { getAuthentication, getUserInfo } from '../services/auth';
import {
  IAffiliate,
  IAffiliateInfo,
  IAccess,
  IUserAccess,
  IUser,
  IUserInfo,
  StatusBootWhats,
} from '../models';
// import { RootState } from './store';

export const loginUser = createAsyncThunk(
  'users/authenticate',
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    try {
      const response = await getAuthentication(username, password);
      const { token } = response.data;

      if (response.status === 200) {
        localStorage.removeItem('licExp');
        localStorage.setItem('token', token);
        return response.data;
      }

      return thunkAPI.rejectWithValue(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkAPI.rejectWithValue(
        e.response?.data || 'Erro na autenticação dos dados.'
      );
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  'user',
  // eslint-disable-next-line no-empty-pattern, prettier/prettier
  async (_, thunkAPI) => {
    try {
      const response = await getUserInfo();

      if (response.status === 200) {
        const licenceExp = moment(
          response.data.affiliate.licence_expiration_at
        );
        const currentMoment = moment();

        if (licenceExp.isBefore(currentMoment)) {
          localStorage.setItem('licExp', licenceExp.toString());
          throw new Error();
        }

        return response.data;
      }

      return thunkAPI.rejectWithValue(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      localStorage.removeItem('affiliate');
      localStorage.removeItem('affiliateSelection');
      localStorage.removeItem('token');
      localStorage.removeItem('access');

      window.location.href = '/';

      return thunkAPI.rejectWithValue(
        e.response?.data || 'Erro na atualização dos dados.'
      );
    }
  }
);

const strAffiliate = localStorage.getItem('affiliate');
const strAccess = localStorage.getItem('access');

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {} as IUserInfo,
    affiliate: strAffiliate ? JSON.parse(strAffiliate) : ({} as IAffiliateInfo),
    access: strAccess ? JSON.parse(strAccess) : ({} as IAccess),
    enable_multiple_affiliate: false,
    isLogin: false,
    isFetching: true,
    isSuccess: false,
    isError: false,
    errorMessage: {},
  } as IUserAccess,
  reducers: {
    clearState: state => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
      state.isLogin = false;

      return state;
    },
    logout: state => {
      state.user = {} as IUserInfo;
      state.affiliate = {} as IAffiliateInfo;
      state.access = {} as IAccess;
      state.enable_multiple_affiliate = false;
      state.isFetching = true;
      state.isLogin = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = {
        status: '',
        message: '',
      };

      localStorage.removeItem('affiliate');
      localStorage.removeItem('affiliateSelection');
      localStorage.removeItem('token');
      localStorage.removeItem('access');
      localStorage.removeItem('licExp');

      return state;
    },
    setUser: (state: IUserAccess, action: PayloadAction<IUser>) => {
      state.user.name = action.payload.name;
      state.user.username = action.payload.username || '';
    },
    setStatusWhatsBoot: (
      state: IUserAccess,
      action: PayloadAction<StatusBootWhats>
    ) => {
      state.affiliate.whatsapp_session_status = action.payload;
    },
    setSituationAffiliate: (
      state: IUserAccess,
      action: PayloadAction<'opened' | 'closed'>
    ) => {
      state.affiliate.situation = action.payload;
    },
    setAffiliate: (state: IUserAccess, action: PayloadAction<IAffiliate>) => {
      const affiliate = {
        id: action.payload.id,
        corporate_name: action.payload.corporate_name,
        fantasy_name: action.payload.fantasy_name,
        delivery_time_final: action.payload.delivery_time_final,
        withdrawal_time_final: action.payload.withdrawal_time_final,
        address: action.payload.address,
        address_number: action.payload.address_number,
        citie_name: action.payload.citie?.name,
        citie_state: action.payload.citie?.state,
        neighborhood_name: action.payload.neighborhood?.name,
        cart_observation: action.payload.cart_observation,
        local_menu_observation: action.payload.local_menu_observation,
        local_menu_module_enabled: action.payload.local_menu_module_enabled,
        licence_expiration_at: action.payload.licence_expiration_at,
        whatsapp_session_status: action.payload.whatsapp_session_status,
        cashback_module_enabled: action.payload.cashback_module_enabled,
        cashback_percentage: action.payload.cashback_percentage,
        situation: action.payload.situation,
      } as IAffiliateInfo;

      state.affiliate = affiliate;
      localStorage.setItem('affiliate', JSON.stringify(affiliate));
    },
    setChangeLog: (state: IUserAccess) => {
      state.user.show_changelog = false;
    },
  },
  extraReducers: {
    [loginUser.fulfilled.type]: (state, { payload }) => {
      state.user = payload;
      // state.enable_multiple_affiliate = payload.enable_multiple_affiliate;
      // state.isFetching = false;
      state.isSuccess = true;
      state.isLogin = false;

      return state;
    },
    [loginUser.rejected.type]: (state, { payload }) => {
      state.isLogin = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending.type]: state => {
      state.isLogin = true;
    },
    [fetchUserBytoken.pending.type]: state => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      state.user = payload.user;
      state.affiliate = payload.affiliate;
      state.access = {
        type: state.user.type,
        permissions: payload.permissions,
      } as IAccess;

      localStorage.setItem('access', JSON.stringify(state.access));
    },
    [fetchUserBytoken.rejected.type]: state => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const {
  clearState,
  logout,
  setUser,
  setSituationAffiliate,
  setStatusWhatsBoot,
  setAffiliate,
  setChangeLog,
} = userSlice.actions;

// export const userSelector = (state: RootState) => state.user;
