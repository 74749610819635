import React from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export default function SubtitleInfo({ children }: Props) {
  return (
    <Stack direction="row" sx={{ mt: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
    </Stack>
  );
}
