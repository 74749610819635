/* eslint-disable prettier/prettier */
import React from 'react';
import MUIDataTable, {
  MUIDataTableProps,
  MUIDataTableOptions,
  SelectableRows,
} from 'mui-datatables';
import { CircularProgress } from '@mui/material';
import CustomToolbar from './CustomToolbar';

interface Props {
  title: string | React.ReactNode;
  titleAdd?: string;
  hideActionAddToolbar?: boolean;
  isLoading?: boolean;
  rowsPerPage?: number;
  height?: string;
  action?: () => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh?: () => Promise<any>;
  setRowProps?:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((row: any[], dataIndex: number, rowIndex: number) => object)
  | undefined;
  pagination?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customTableBodyFooterRender?: (options: { data: any[]; selectableRows: SelectableRows; columns: any[] }) => any;
}

export default function DataTable({
  title,
  titleAdd,
  hideActionAddToolbar,
  isLoading,
  rowsPerPage,
  height,
  action,
  refresh,
  setRowProps,
  pagination,
  customTableBodyFooterRender,
  ...muiTableProps
}: MUIDataTableProps & Props) {
  const options: MUIDataTableOptions = {
    rowsPerPageOptions: [],
    rowsPerPage,
    searchPlaceholder: 'Digite aqui para pesquisar',
    selectableRows: 'none',
    tableBodyHeight: height,
    setRowProps,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <div>
            <CircularProgress color="primary" />
          </div>
        ) : (
          'Não há registros a serem exibidos'
        ),
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        displayRows: 'de',
      },
      filter: {
        title: 'Pesquisar',
      },
      viewColumns: {
        title: 'Colunas',
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Configurar Colunas',
      },
    },
    download: false,
    filter: false,
    print: false,
    pagination,
    viewColumns: false,
    // eslint-disable-next-line react/no-unstable-nested-components
    customToolbar: () => {
      return (
        <CustomToolbar
          hideActionAddToolbar={hideActionAddToolbar}
          titleAdd={titleAdd ?? 'Adicionar'}
          refresh={refresh}
          action={action}
        />
      );
    },
    customTableBodyFooterRender,
  };

  return <MUIDataTable {...muiTableProps} title={title} options={options} />;
}

DataTable.defaultProps = {
  hideActionAddToolbar: false,
  isLoading: false,
  rowsPerPage: 4,
  action: undefined,
  refresh: undefined,
  titleAdd: undefined,
  setRowProps: undefined,
  height: 'auto',
  pagination: true,
  customTableBodyFooterRender: undefined,
};
